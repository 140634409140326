/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import getAppUrl from '@cron/shared/getAppUrl.js'

export const paths = [{ path: '/login' }]

const appUrl = getAppUrl()

const LoginRedirect = () => {
  const [searchParams] = useSearchParams()
  // console.log(searchParams)
  if (typeof window !== 'undefined') {
    window.location = `${appUrl}/login?${searchParams}`
  }
}

export default LoginRedirect
