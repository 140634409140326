/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import getAppUrl from '@cron/shared/getAppUrl.js'

const appUrl = getAppUrl()

export const paths = [{ path: '/signup' }]

const SignupRedirect = () => {
  const [searchParams] = useSearchParams()
  // console.log(searchParams)
  if (typeof window !== 'undefined') {
    window.location = `${appUrl}/signup?${searchParams}`
  }
}

export default SignupRedirect
