/* Copyright 2013 - 2022 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getCronAppUrl() {
  let url = 'https://app.cron.cool'

  if (IS_BROWSER && window.CRON_APP_URL === '{{{CRON_APP_URL}}}') {
    delete window.CRON_APP_URL
  }

  if (IS_NODE && process.env.CRON_APP_URL === '{{{CRON_APP_URL}}}') {
    delete process.env.CRON_APP_URL
  }

  if (IS_BROWSER && window.CRON_APP_URL) {
    url = `${window.CRON_APP_URL}`
  } else if (IS_NODE && process.env.CRON_APP_URL) {
    url = process.env.CRON_APP_URL
  } else if (IS_STAGING) {
    url = 'https://app.cron-staging.cool'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://app.cron-staging.cool';
    // url = 'http://192.168.1.64:16262';
    url = 'http://localhost:16262'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Cron.url = ' + url)
  // }

  return url
}
