/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'
import domainProduction from '@cron/shared/domainProduction.js'
import getAppUrl from '@cron/shared/getAppUrl.js'
import NavigationComponent from '@stiloso/components/NavigationComponent.js'
import PricingSection from '@stiloso/sections/PricingSection.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconBrand from '@cron/shared/IconBrand.js'
import FooterComponent from '../components/FooterComponent.js'
import languages from '../languages.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = t('Cron')
  const description = t('__pricing_title__')

  return (
    <>
      <title>{`${description} | ${title}`}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* <meta name="twitter:image" content={workingOnComputerIcon} /> */}
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="seat" />
      <meta property="og:url" content={`https://www.${domainProduction}/`} />
      {/* <meta property="og:image" content={workingOnComputerIcon} /> */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {/* <meta itemProp="image" content={workingOnComputerIcon} /> */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            '@id': `https://www.${domainProduction}/${locale}/`,
            url: `https://www.${domainProduction}/${locale}/`,
            inLanguage: locale,
            isPartOf: {
              '@id': `https://www.${domainProduction}/${locale}/#website`,
            },
            author: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            publisher: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            translationOfWork: {
              '@id': `https://www.${domainProduction}/en/#website`,
            },
            '@graph': [
              {
                '@type': 'WebSite',
                '@id': `https://www.${domainProduction}/${locale}/#website`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/${locale}/#website`,
                inLanguage: locale,
                author: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
                publisher: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
              },
              {
                '@type': 'Corporation',
                '@id': `https://www.${domainProduction}/#corporation`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/#corporation`,
                sameAs: [
                  `https://www.facebook.com/${domainProduction}`,
                  'https://www.linkedin.com/company/multilocale',
                ],
              },
            ],
          }),
        }}
      />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/pricing/`,
  props: {
    locale,
  },
}))

const PricingPage = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <>
      <NavigationComponent
        locale={locale}
        AppIcon={<IconBrand light />}
        appUrl={getAppUrl()}
        appName={t('Cron')}
        logoText
      />
      <PricingSection
        locale={locale}
        plans={[
          {
            amount: 0,
            billingFrequency: 'MONTHLY',
            currencyCode: 'USD',
            name: 'Free',
            lines: [t('{seats_count} seats').replace('{seats_count}', 2)],
          },
          {
            amount: 19,
            billingFrequency: 'MONTHLY',
            currencyCode: 'USD',
            name: 'Basic',
            lines: [t('{seats_count} seats').replace('{seats_count}', 4)],
          },
          {
            amount: 49,
            billingFrequency: 'MONTHLY',
            currencyCode: 'USD',
            name: 'Plus',
            lines: [t('{seats_count} seats').replace('{seats_count}', 10)],
          },
          {
            amount: 89,
            billingFrequency: 'MONTHLY',
            currencyCode: 'USD',
            name: 'Pro',
            lines: [t('{seats_count} seats').replace('{seats_count}', '∞')],
          },
        ]}
      />
      <FooterComponent locale={locale} />
    </>
  )
}

export default PricingPage
